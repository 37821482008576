import React from 'react'
import { PageMainWrapper } from 'components/dpc/style'
import Nav from 'components/dpc/Nav'
import Footer from 'components/Footer'
import ContactFooter from 'components/ContactFooter'
import styled from 'styled-components'
import { colors, sizes } from 'theme'
import Separator from 'components/landing/Separator'
import { USER } from 'graphql/queries/user'
import { useQuery } from '@apollo/client'
import {
  Hero,
  Main,
  SatisfactionSurvey,
} from 'components/dpc/TrainingSpace'

const TrainingSpace = () => {
  const userQuery = useQuery(USER)
  const hasAccess = Boolean(userQuery?.data?.me?.dpcAccess)
  const hasCompleted = userQuery?.data?.me?.dpcAccess?.isCompleted

  return (
    <>
      <Nav background={ true } />
      <Wrapper>
        <Hero />
        <Separator />
        { hasAccess && <Main /> }
        { hasCompleted && <SatisfactionSurvey /> }
      </Wrapper>
      <ContactFooter />
      <Footer />
    </>
  )
}

const Wrapper = styled(PageMainWrapper)`
  padding: ${sizes.heroesPaddingTop} 1rem 4rem;
  color: ${colors.darkBlue};

  h1 {
    font-weight: 900;
    text-align: left;

    em {
      font-weight: 900;
      color: ${colors.purple};
    }
  }

  h3 {
    margin-top: 4rem;
  }

  h4 {
    margin-top: 3rem;
    font-size: 1.25rem;
    font-weight: 700;
  }

  em {
    font-weight: bold;
  }

  article {
    text-align: left;
  }

  a {
    width: fit-content;
  }

  ${Separator} {
    margin: 3rem auto 0;
  }

  .italic {
    font-style: italic;
  }
`

export default TrainingSpace
