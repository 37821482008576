import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Button,
  FormInput,
} from 'components/atoms'
import { colors } from 'theme'
import Separator from 'components/landing/Separator'
import { isUbipharm } from 'utils/origin'
import { InputType } from 'components/atoms/Input'

const ContactForm = ({
  errors,
  form,
  onChange,
  onSubmit,
}) => {
  const {
    lastname,
    firstname,
    email,
    address,
  } = form

  return (
    <Wrapper id="subscriptions">
      <Title>Formulaire de contact</Title>
      <MessageWrapper>
        <Message>
          { 'UBIPHARM vous offre un an d\'abonnement gratuit à Défimédoc* ! \n'}
          { 'Laissez-nous vos coordonnées, nous prendrons contact avec vous pour mettre en place votre abonnement. \n'}
          { '* sous réserve des conditions générales d\'adhésion à UBIPHARM. \n'}
        </Message>
      </MessageWrapper>
      <StyledSeparator />
      <form
        autoComplete="off"
        onSubmit={ onSubmit }
      >
        <Row className="wrappable">
          <InputWrapper>
            <FormInput
              error={ errors.lastname }
              label="Nom"
              name="lastname"
              type={ InputType.TEXT }
              value={ lastname }
              onChangeText={ onChange }
            />
          </InputWrapper>
          <InputWrapper>
            <FormInput
              error={ errors.firstname }
              label="Prénom"
              name="firstname"
              type={ InputType.TEXT }
              value={ firstname }
              onChangeText={ onChange }
            />
          </InputWrapper>
        </Row>
        <Row className="wrappable">
          <InputWrapper>
            <FormInput
              error={ errors.email }
              label="Email"
              name="email"
              type={ InputType.EMAIL }
              value={ email }
              onChangeText={ onChange }
            />
          </InputWrapper>
          <InputWrapper>
            <FormInput
              error={ errors.address }
              label={ isUbipharm() ? 'Adresse de la pharmacie' : 'Adresse' }
              name="address"
              type={ InputType.TEXT }
              value={ address }
              onChangeText={ onChange }
            />
          </InputWrapper>
        </Row>
        <SendButton
          size="large"
          type="submit"
          variant="primary"
        >
          {'Envoyer le formulaire'}
        </SendButton>
      </form>
    </Wrapper>
  )
}

ContactForm.propTypes = {
  errors: PropTypes.object.isRequired,
  form: PropTypes.shape({
    lastname: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin: 2rem;
`

const Title = styled.h2`
  font-family: "Brother 1816";
  font-size: 2.25rem;
  font-style: bold;
  font-weight: bold;
  color: ${colors.midnightBlue};
  text-align: center;
`

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`

const Message = styled.p`
  margin: 0;
  font-family: Montserrat;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2.25rem;
  color: ${colors.midnightBlue};
  text-align: center;
  white-space: pre-line;
`

const StyledSeparator = styled(Separator)`
  align-self: center;
  width: 4.4rem;
`

const Row = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: flex-end;
  margin: 0 20%;

  & > div:last-child {
    margin-right: 0;
  }

  @media (width <= 580px) {
    margin: 0;

    &.wrappable {
      flex-wrap: wrap;

      & > * {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
`

const InputWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 1.3125rem;
`

const SendButton = styled(Button)`
  && {
    align-self: center;
    padding: 0.8rem 2.5rem;
    margin: 2rem 0 1rem;
    font-size: 1.5rem;
    border-radius: 0;
  }
`

export default ContactForm
