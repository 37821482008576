import React, { useId, useState } from 'react'
import { FormElement } from 'components/atoms'
import styled from 'styled-components'
import Input, { InputType } from 'components/atoms/Input'

type Props<T extends string> = {
  disabled?: boolean;
  error?: string;
  label: string;
  name: T;
  value?: string | number | null;
  onChangeText: (value: string, field: T) => void
}

const FormPasswordInput = <T extends string>({
  label,
  name,
  error,
  value,
  disabled = false,
  onChangeText,
}: Props<T>) => {
  const id = useId()
  const [secureTextEntry, setSecureTextEntry] = useState(true)

  const handleEyeClick = () => {
    setSecureTextEntry(!secureTextEntry)
  }

  return (
    <FormElement
      error={ error }
      label={ label }
      name={ id }
    >
      <InputWrapper>
        <StyledInput
          disabled={ disabled }
          error={ error }
          icon={ secureTextEntry ? 'eye' : 'crossedEye' }
          id={ id }
          name={ name }
          type={ secureTextEntry ? InputType.PASSWORD : InputType.TEXT }
          value={ value }
          onChangeText={ onChangeText }
          onIconClick={ handleEyeClick }
        />
      </InputWrapper>
    </FormElement>
  )
}

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  max-width: 100%;
`

const StyledInput: typeof Input = styled(Input)`
  padding: 2.5rem 0;
`

export default FormPasswordInput
