import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { logError } from 'logService'
import { Button, FormInput, FormSelect } from 'components/atoms'
import { BILLING_ADDRESS } from 'graphql/queries/user'
import { UPDATE_MY_PROFILE } from 'graphql/mutations/user'
import { invalidField, mandatoryField } from 'constants/form'
import { countries, DEFAULT_COUNTRY } from 'constants/countries'
import useForm from 'hooks/useForm'
import { useQuery, useMutation } from '@apollo/client'
import { MyBillingAddressQuery, UpdateMyProfileMutation, UpdateMyProfileMutationVariables } from '@/types/graphql'
import { toast } from 'react-toastify'
import zod from 'zod'
import { colors } from 'theme'
import styled from 'styled-components'
import { InputType } from 'components/atoms/Input'

const billinAddressFormSchema = zod.object({
  name: zod.string().min(1, mandatoryField),
  address: zod.string().min(1, mandatoryField),
  address2: zod.string(),
  city: zod.string().min(1, mandatoryField),
  country: zod.string().min(1, mandatoryField),
  zipCode: zod.string().regex(/^\d{5}$/, invalidField).min(1, mandatoryField),
})

const countriesOptions = countries.map(country => ({
  label: country,
  value: country,
}))

export type BillingAddressFormType = zod.infer<typeof billinAddressFormSchema>

const BillingAddressForm = () => {
  const [editionMode, setEditionMode] = useState(false)

  const [updateMyProfile] = useMutation<UpdateMyProfileMutation, UpdateMyProfileMutationVariables>(UPDATE_MY_PROFILE)
  const userQuery = useQuery<MyBillingAddressQuery>(BILLING_ADDRESS)
  const billingAddress = userQuery.data?.me?.billingAddress
  const pharmacy = userQuery.data?.me?.pharmacy

  const initialData = useMemo(() => ({
    name: pharmacy?.name ?? '',
    address: billingAddress?.address ?? '',
    address2: billingAddress?.address2 ?? '',
    city: billingAddress?.city ?? '',
    country: billingAddress?.country ?? DEFAULT_COUNTRY,
    zipCode: billingAddress?.zipCode ?? '',
  }), [
    billingAddress,
    pharmacy?.name,
  ])

  const handleBillingAddressFormSubmit = useCallback(async (form: BillingAddressFormType) => {
    const {
      address,
      address2,
      city,
      zipCode,
      country,
    } = form

    try {
      await updateMyProfile({
        variables: {
          user: {
            billingAddress: {
              address,
              address2,
              city,
              zipCode,
              country,
            },
          },
        },
        refetchQueries: [{
          query: BILLING_ADDRESS,
        }],
        awaitRefetchQueries: true,
      })
      setEditionMode(false)
    } catch (e) {
      toast.error('Oups, une erreur est survenue', {
        position: 'top-center',
      })
      logError('GRAPHQL', 'updateMyProfile', e)
    }
  }, [updateMyProfile])

  const [data, errors, handleChange, handleSubmit, setData] = useForm(initialData, billinAddressFormSchema, handleBillingAddressFormSubmit)

  const selectedCountryOption = useMemo(
    () => countriesOptions.find(option => option.value === data.country),
    [data.country],
  )

  useEffect(() => {
    setData(initialData)
  }, [initialData, setData])

  const handleToggleEdit = useCallback(() => {
    setEditionMode(!editionMode)
  }, [editionMode])

  const handleCancel = useCallback(() => {
    setEditionMode(false)
    setData(initialData)
  }, [initialData, setData])

  if (userQuery.loading || userQuery.data?.me == null) {
    return null
  }

  return (
    <Wrapper>
      <Top>
        <Title>
          ADRESSE DE FACTURATION
        </Title>
        <Button
          variant="tertiary"
          onClick={ handleToggleEdit }
        >
          Modifier
        </Button>
      </Top>
      { editionMode
        ?
          <form onSubmit={ handleSubmit }>
            <FormInput
              error={ errors.address }
              label="N° et nom de la voie"
              name="address"
              type={ InputType.TEXT }
              value={ data.address }
              onChangeText={ handleChange }
            />
            <FormInput
              error={ errors.address2 }
              label="Complément d’adresse"
              name="address2"
              type={ InputType.TEXT }
              value={ data.address2 }
              onChangeText={ handleChange }
            />
            <FormInput
              error={ errors.zipCode }
              label="Code postal"
              name="zipCode"
              type={ InputType.TEXT }
              value={ data.zipCode }
              onChangeText={ handleChange }
            />
            <FormInput
              error={ errors.city }
              label="Ville"
              name="city"
              type={ InputType.TEXT }
              value={ data.city }
              onChangeText={ handleChange }
            />
            <FormSelect
              error={ errors.country }
              label="Pays"
              name="country"
              options={ countriesOptions }
              selectedOption={ selectedCountryOption }
              onChange={ handleChange }
            />
            <EditionButtons>
              <CancelEdition
                size="small"
                variant="tertiary"
                onClick={ handleCancel }
              >
                Annuler
              </CancelEdition>
              <ConfirmEdition
                size="small"
                type="submit"
                variant="primary"
              >
                Valider
              </ConfirmEdition>
            </EditionButtons>
          </form>
        : billingAddress?.address != null ? (
          <Preview>
            <PharmacyName>
              { pharmacy?.name }
            </PharmacyName>
            <PreviewField>
              { billingAddress.address }
            </PreviewField>
            <PreviewField>
              { billingAddress.address2 }
            </PreviewField>
            <PreviewField>
              { `${billingAddress.zipCode}, ${billingAddress.city}` }
            </PreviewField>
            <PreviewField>
              { billingAddress.country }
            </PreviewField>
          </Preview>
        ) : (
          <Preview>
            <PreviewField>
              Aucune adresse de facturation renseignée
            </PreviewField>
          </Preview>
        )
      }

    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Top = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const Title = styled.span`
  margin-bottom: 1.5rem;
  font-family: Montserrat;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  color: ${colors.darkGray};
  opacity: 0.5;
`

const EditionButtons = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  margin-top: 2rem;
`

const CancelEdition = styled(Button)`
  && {
    margin-right: 1rem;
  }
`

const ConfirmEdition = styled(Button)`
  && {
    padding: 0.83rem 1.5rem;
    background-color: ${colors.lightGreen};
    background-image: none;
    border-radius: 0;
    box-shadow: none;
  }
`

const Preview = styled.div`
  display: flex;
  flex-direction: column;
`

const PharmacyName = styled.span`
  margin-bottom: 0.5rem;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: bold;
  color: ${colors.midnightBlue};
`

const PreviewField = styled.span`
  margin-bottom: 0.5rem;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: normal;
  color: ${colors.midnightBlue};
`

export default BillingAddressForm
