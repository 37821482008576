import React from 'react'
import CookieConsent from 'react-cookie-consent'
import styled from 'styled-components'

const CookieBanner = () =>
  <CookieConsent
    buttonStyle={ buttonStyle }
    buttonText="J'accepte"
    cookieName='cookie-consent'
    location='bottom'
    style={ {
      backgroundColor: '#032148',
    } }
  >
    <CookiePresentation>
      Le fonctionnement du site Défimédoc est garanti par l’utilisation de
      certains cookies strictement nécessaires qui vous permettent de naviguer
      dans le site et d’utiliser des fonctionnalités essentielles, comme les
      espaces sécurisés, le panier d&apos;achat et les pages de paiement
      sécurisées. Toutes les informations recueillies par ces cookies sont
      envoyées uniquement à notre site web. En poursuivant votre navigation vous
      en acceptez l&apos;utilisation.
    </CookiePresentation>
  </CookieConsent>

const CookiePresentation = styled.p`
  font-family: 'Brother 1816';
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.875rem;
  color: '#fff';

  @media (width <= 1050px) {
    font-size: 0.9rem;
  }

  @media (width <= 700px) {
    font-size: 0.8rem;
  }
`

const buttonStyle = {
  margin: '15px',
  padding: '1rem 3rem',
  fontFamily: 'Montserrat',
  fontSize: '0.8em',
  borderRadius: '4px',
  boxShadow: 'rgba(0,0,0,0.35) 0 2px 5px 0',
  backgroundImage:
    'linear-gradient(to bottom right,rgb(35,188,186),rgb(0,229,166))',
  color: '#fff',
  fontWeight: '800',
  alignSelf: 'center',
}

export default CookieBanner
