import React from 'react'
import styled from 'styled-components'
import { Nav } from 'components/Nav'
import { colors } from 'theme'
import { useNavigate, useLocation } from 'react-router'
import PropTypes from 'prop-types'
import { USER } from 'graphql/queries/user'
import { useQuery } from '@apollo/client'
import AuthLinks from 'components/Nav/AuthLinks'
import { Button } from 'components/atoms'

const onLinkClick = (navigate, link) => () => {
  navigate(link)
}

const isActive = (location, link) => location.pathname === link

const DpcNav = ({ background, className, ...props }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const navItems = [
    { to: '/dpc', content: 'Présentation' },
    { to: '/dpc/training', content: 'La formation' },
    { to: '/dpc/team', content: 'L\'équipe' },
  ].map(item => ({ ...item, active: isActive(location, item.to) }))
  const userQuery = useQuery(USER)
  const isLogged = Boolean(userQuery?.data?.me)
  const hasDPCAccess = Boolean(userQuery?.data?.me?.dpcAccess)

  return (
    <BackgroundableNav
      className={ (background ? 'background ' : '') + className }
      navItems={ navItems }
      { ...props }
    >
      <Wrapper className="text-description">
        <Button
          className="blue-button"
          onClick={ onLinkClick(navigate, '/') }
        >
          L&apos;application Defimedoc
        </Button>
        { !isLogged && <AuthLinks /> }
        { hasDPCAccess
          && (
            <button onClick={ onLinkClick(navigate, '/dpc/training-space') }>
              Mon espace formation
            </button>
          )
        }
      </Wrapper>
    </BackgroundableNav>
  )
}

DpcNav.propTypes = {
  background: PropTypes.bool,
  className: PropTypes.string,
}

DpcNav.defaultProps = {
  background: false,
  className: '',
}

const BackgroundableNav = styled(Nav)`
  &.background {
    background-image: url(${require('assets/images/dpc/background-top.png')});
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;

  button {
    font-weight: bold;
    color: ${colors.white};
  }

  .blue-button {
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    background: linear-gradient(158.68deg, ${colors.deepBlue} -17.76%, ${colors.blueLagoon} 119.37%);
    border-radius: 22.5px;
  }

  @media (width <= 600px) {
    button {
      font-size: 0.75rem;
    }
  }
`

export default DpcNav
