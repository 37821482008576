import React, { useId } from 'react'
import { FormElement, Icon } from 'components/atoms'
import { colors } from 'theme'
import Input, { InputType } from 'components/atoms/Input'

type Props<T extends string> = {
  disabled?: boolean;
  error?: string;
  icon?: string;
  label: string;
  maxLength?: number;
  name: T;
  placeholder?: string;
  type: InputType;
  value?: string | number | null;
  onChangeText: (value: string, field: T) => void;
}

const FormInput = <T extends string>({
  label,
  icon,
  error,
  disabled = false,
  placeholder,
  maxLength,
  name,
  type,
  value,
  onChangeText,
}: Props<T>) => {
  const id = useId()

  return (
    <FormElement
      error={ error }
      label={ label }
      name={ id }
    >
      { icon
        ?
          <div>
            <Icon
              fill={ colors.lightGreen }
              name={ icon }
              size="1em"
            />
            <Input
              disabled={ disabled }
              error={ error }
              id={ id }
              maxLength={ maxLength }
              name={ name }
              placeholder={ placeholder }
              type={ type }
              value={ value }
              onChangeText={ onChangeText }
            />
          </div>
        :
          <Input
            disabled={ disabled }
            error={ error }
            id={ id }
            maxLength={ maxLength }
            name={ name }
            placeholder={ placeholder }
            type={ type }
            value={ value }
            onChangeText={ onChangeText }
          />
      }
    </FormElement>
  )
}

export default FormInput
