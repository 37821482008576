import React from 'react'
import { Helmet } from 'react-helmet'
import CartProvider from 'context/Cart'
import ModalProvider from 'context/Modal'
import 'react-toastify/dist/ReactToastify.css'
import GlobalStyle from 'assets/GlobalStyle'
import client from 'apolloClient'
import ToastContainer from 'components/ToastContainer'
import CookieBanner from 'components/CookieBanner'
import { isSubdomain } from 'utils/origin'
import PropTypes from 'prop-types'

// Needed ! See https://github.com/styled-components/styled-components/issues/1593
import 'assets/fonts.css'
import 'logService'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

const App = ({ ApolloProvider, Router }) => {
  if (null == process.env.REACT_APP_STRIPE_KEY) {
    throw new Error('REACT_APP_STRIPE_KEY is not defined')
  }

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
  const stripeOptions = {
    locale: 'fr',
    mode: 'setup',
    cur: 'eur',
    paymentMethodTypes: ['card', 'sepa_debit'],
    paymentMethodCreation: 'manual',
  }

  return (
    <Elements
      options={ stripeOptions }
      stripe={ stripePromise }
    >
      { isSubdomain() ?
        <Helmet>
          <meta content="noindex, nofollow"
            name="robots"
          />
          <meta content="noindex"
            name="googlebot"
          />
        </Helmet> : null
      }
      <GlobalStyle />
      <ToastContainer />
      <ApolloProvider client={ client }>
        <ModalProvider>
          <CartProvider>
            <Router />
          </CartProvider>
        </ModalProvider>
      </ApolloProvider>
      <CookieBanner />
    </Elements>
  )
}

App.propTypes = {
  ApolloProvider: PropTypes.func.isRequired,
  Router: PropTypes.func.isRequired,
}

export default App
