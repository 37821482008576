import React from 'react'
import styled from 'styled-components'
import {
  Header,
  Footer,
} from 'components/auth'
import ResetPasswordForm from 'components/ResetPassword'

const ResetPassword = props =>
  <Wrapper>
    <Main>
      <Header />
      <ResetPasswordForm { ...props } />
      <Footer />
    </Main>
    <Placeholder />
  </Wrapper>

const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  height: 100%;
`

const Main = styled.div`
  display: flex;
  flex: 0.6;
  flex-direction: column;
  padding: 2rem 4rem;
`

const Placeholder = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  height: 100%;
  background-image: url(${require('assets/images/authhub/background.jpg')});
  background-repeat: no-repeat;
`

export default ResetPassword
