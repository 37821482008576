import React from 'react'
import {
  Hero,
  Main,
} from 'components/dpc/Presentation'
import {
  Nav,
} from 'components/dpc'
import Footer from 'components/Footer'
import ContactFooter from 'components/ContactFooter'

const Presentation = () => {
  return (
    <div>
      <Nav />
      <Hero />
      <Main />
      <ContactFooter />
      <Footer />
    </div>
  )
}

export default Presentation
