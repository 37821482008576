import React, { useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Cookies from 'js-cookie'
import styled from 'styled-components'
import ForgottenPassword from './ForgottenPassword'
import { FORGOTTEN_PASSWORD_PATH, LOGIN_PATH } from 'components/Router'
import AuthNav from './AuthNav'
import Login from './Login'
import Register from './Register'

const Container = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { invitationToken } = useParams()
  const isLoginSelected = useMemo(() => location.pathname === LOGIN_PATH, [location.pathname])

  if (invitationToken !== undefined) {
    // defaults to current session's life
    Cookies.set('invitationToken', invitationToken)
  }

  return (
    <Wrapper>
      {
        location.pathname === FORGOTTEN_PASSWORD_PATH
        ?
          <ForgottenPassword onBack={ () => navigate(-1) } />
        :
          <>
            <AuthNav
              isLoginSelected={ isLoginSelected }
            />
            { isLoginSelected
              ?
                <Login />
              :
                <Register />
            }
          </>
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: center;
  width: 65%;
  padding: 3.7rem 0;
`

export default Container
