import React from 'react'
import Markdown from 'components/atoms/Markdown'
import styled from 'styled-components'

const PersonalData = () =>
  <Wrapper>
    <Title>Données personnelles</Title>
    <Markdown>
      {
        `
- Vous vous apprêtez à utiliser les services fournis par la société Défimédoc. Dans ce cadre, Défimédoc est amenée à collecter et traiter des informations qui vous concernent et certaines permettent de vous identifier. Ces données-ci sont appelées « données personnelles ».

- Dans le souci d’assurer la meilleure transparence, nous vous proposons de prendre connaissance de notre politique de confidentialité qui vous indique comment nous collectons et traitons vos données personnelles.

- Pour toute question relative à cette politique/charte ou à la manière dont Défimédoc traite vos données personnelles, vous pouvez contacter notre délégué à la protection des données (DPO) à l’adresse suivante : contact@defimedoc.fr

- Plus d’information : www.cnil.fr

- **Responsable du traitement et délégué à la protection des données**

  La personne qui est identifiée comme « responsable du traitement » au sens de la loi applicable est Mme Florence BONTEMPS, née le 01/12/1960 à Paris, France, de nationalité française, résidant 20 bis avenue Joffre à La Garenne-Colombes (92250).

- **Quelles données et quelles utilisations ?**

- Les données que vous nous communiquez volontairement dans le cadre de votre accès à nos services.

- Dans le cadre de nos échanges comme par exemple pour l’exécution d’un contrat qui nous lie ou pour vous inscrire sur nos newsletters ou constituer votre espace personnel sur nos sites et/ou applications, vous nous communiquez certaines données personnelles (adresse courriel, prénom, âge…).
- Lorsque vous contactez nos services, quels qu’ils soient, pour assurer le suivi de vos demandes et l’organisation de notre activité nous devons conserver certaines de vos données personnelles. Par exemple lorsque vous contactez notre support, que vous nous signalez un problème ou que vous souhaitez bénéficier d’une offre commerciale particulière.

- Les données statistiques et les données personnelles que nous recueillons automatiquement.

- Statistiques anonymes. Nous pouvons collecter des données anonymes visant à l’analyse statistique de l’utilisation de nos services (nombres de connexions, temps d’accès, etc.). Ces données ne contiennent pas de données personnelles et ne présentent aucun impact ou menace sur votre vie privée.

- Cookies. Nous utilisons par exemple des témoins de connexion et navigation (cookies). Un témoin de connexion est l'équivalent d'un fichier texte de petite taille, stocké sur le terminal de l'internaute. Un cookie peut être utilisé pour une authentification, une session et pour stocker une information spécifique sur l'utilisateur, comme les préférences d'un site ou le contenu d'un panier d'achat électronique. Un témoin de connexion n’a pas pour objet de vous identifier personnellement. Cela étant, il contient certaines données personnelles.
Reportez-vous à la documentation de votre navigateur internet pour configurer le traitement des témoins de connexion.

- Grâce aux témoins de connexion, Défimédoc collecte des informations (dont certaines sont des données personnelles) afin d’identifier :
. les outils matériels et logiciels utilisés pour accéder à nos services tels que les équipements (adresse IP de votre ordinateur, identifiant smartphone, etc.), le système d’exploitation, le nom et la version du logiciel de navigation.
. les habitudes d’accès et d’utilisation de nos services tels que les dates et heures de connexion, les pages et contenus visités, les chemins d’accès (la navigation de page en page).

- Données issues des protocoles de l’internet. L’utilisation de l’internet et du web peut impliquer l’utilisation de protocoles ou de standards qui utilisent ou nécessitent le recours à des technologies particulières telles que les scripts, les balises-web (éventuellement invisibles) ou les redirections automatiques (d’une page vers une autre). Ces technologies nous donnent des informations sur votre utilisation de nos services.

- Ces technologies nous servent à garantir un meilleur niveau de service en permettant notamment de personnaliser votre expérience en fonction de vos habitudes d’utilisation et vos centres d’intérêts.

- Grâce à ces technologies de l’internet, DEFIMEDOC collecte des informations (dont certaines sont des données personnelles) afin de mieux connaitre et comprendre :
. votre utilisation de nos services ;
. la présence et l’utilisation des témoins de connexion, la date et l’heure d’accès et de consultation de nos pages web ;
. la consultation, la lecture de nos e-mails et l’accès à nos contenus.

- Données techniques des terminaux mobiles. Certains terminaux peuvent vous fournir un certain nombre de services qui nécessitent que nous interagissions avec eux. Par exemple lorsque vous demandez à être informé en temps réel ou que votre terminal contextualise vos notifications en fonction de votre localisation.

- Grâce à ces technologies de l’internet, Défimédoc collecte des informations (dont certaines sont des données personnelles) afin :
. de monitorer l’audience de nos services ;
. de garantir le bon fonctionnement des applications mobiles.

- Nous vous rappelons que vous avez la possibilité d’empêcher à tout moment que certaines données ne soient communiqués par ce biais en configurant votre terminal pour qu’il ne les communique pas. Dans ce cas et conformément à la règlementation applicable, nous ne collectons pas ces données.


- Données déduites par recoupement. Nous sommes amenés, à partir d’un faisceau de données existantes générales et anonymes, à déduire certaines informations vous concernant par recoupement ou extrapolation statistique. Ces informations peuvent contenir des données personnelles que nous protégeons et utilisons conformément à la présente charge de confidentialité.

- **Les finalités de l’utilisation de vos données**

- Les données vous concernant que nous collectons nous permettent d’assurer la fourniture de nos services dans de bonnes conditions.

- Dans le respect des lois et des règlements applicables nous allons notamment utiliser vos données personnelles dans le but de réaliser les opérations suivantes.

- Pour fournir nos services :
Gestion des comptes/profils, relation client, gestion des droits d’accès, campagnes d’information et d’enquête, abonnement (alertes, newsletters…)

- Pour vous tenir informés de l’évolution de nos Services :
Information sur le changement de tarifs, l’intégration de nouvelles fonctionnalités…
- Pour le fonctionnement de nos services sur vos terminaux :
Cookies (ergonomie, formulaires, historiques, recherches, mesure d’audience…).

- **Temps de conservation des données**

  Toutes les données sont conservées pendant le temps de votre utilisation des Services et pendant les trois années suivantes dans le but de permettre des restaurations de compte en cas de réabonnement et à des fins de relance d’abonnement ou d’offres émanant de Défimédoc.

- **Protection technique de vos donnees**

  Nous mettons en œuvre des mesures techniques de protection conformément à la règlementation applicable.

- **Qui accède aux données que nous collectons ?**

- Nous-mêmes.
Les personnes directement salariées ou missionnées par nous-même pour assurer les traitements et finalités prévus dans la présente charte.

- Des tiers.
Pour tous les tiers accédant à vos données, nous nous assurons qu’ils sont informés du caractère prsonnel des données et qu’ils s’engagent à les traiter conformément à la règlementation en vigueur.

- Prestataires informatiques.
Nous nous assurons de la protection de vos données en passant des accords avec ces prestataires par lesquels nous les informer du caractère sensible de vos informations.
- La police, la justice ou l’administration.
Nous communiquons à ces autorités les informations que nous sommes légalement contraint de leur communiquer en vue de respecter nos obligations vis-à-vis de la sécurité et le respect des droits.

- **Vos droits**

- Vos droits génériques.
Vous disposez du droit d’accéder, de rectifier, mettre à jour, modifier ou supprimer les données personnelles que nous détenons. Vous disposez également du droit de demander une portabilité de vos données, que nous vous remettrons alors sous un format structuré couramment utilisé par d’autres services.
Vous pouvez exercer ces droits ou nous demander toute information à ce sujet par simple prise de contact : contact@defimedoc.fr

- Votre droit de refuser les cookies.
Vous disposez du droit de refuser l’utilisation de ceux des cookies dont l’utilisation n’est pas nécessaire au fonctionnement correct des Services. Ce refus peut être configuré via les paramètres de votre navigateur internet ou via les fonctionnalités que nous proposons sur le site.`
      }
    </Markdown>
  </Wrapper>

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h1`
  font-family: 'Brother 1816';
  font-style: normal;
  font-weight: 900;
  text-align: center;
`

export default PersonalData
