import React, { FC } from 'react'
import styled from 'styled-components'
import { colors } from 'theme'

type Props = React.HTMLProps<HTMLAnchorElement> & {
  children: React.ReactNode;
  separator?: boolean;
}

const FooterItem: FC<Props> = ({
  children,
  separator = true,
  ...props
}) => {

  return (
    <Wrapper className={ separator ? 'separator' : '' }>
      <a { ...props }>
        {children}
      </a>
    </Wrapper>
  )
}

const Wrapper = styled.li`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  color: ${colors.hanBlue};
  list-style-type: none;

  > a {
    color: ${colors.hanBlue};
  }

  &.separator {
    &::before {
      all: initial;
      padding: 0 0.5rem;
      font-size: 1.5em;
      line-height: 1rem;
      color: ${colors.hanBlue};
      content: "•";
    }
  }
`

export default FooterItem
