import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import {
  Landing,
  Auth,
  Payment,
  PaymentSuccessful,
  TermsOfUse,
  TermsOfServiceApp,
  PersonalData,
  LegalNotice,
  Me,
  MyTeam,
  ResetPassword,
  ThreeDSReturn,
  EmailActivation,
} from 'pages'
import {
  Presentation,
  Training,
  Team,
  TrainingSpace,
} from 'pages/dpc'

export const REGISTER_PATH = '/register'
export const LOGIN_PATH = '/login'
export const FORGOTTEN_PASSWORD_PATH = '/forgotten-password'
export const LANDING_PATH = '/'
export const PAYMENT_SUCCESSFUL_PATH = '/payment-successful'
export const ACTIVATE_EMAIL_PATH = '/active-email'

export const routes = [
  {
    element: (<Landing />),
    path: LANDING_PATH,
  },
  {
    element: (<Auth />),
    path: LOGIN_PATH,
  },
  {
    element: (<Auth />),
    path: REGISTER_PATH,
  },
  {
    element: (<Auth />),
    path: `${REGISTER_PATH}/:invitationToken`,
  },
  {
    element: (<EmailActivation />),
    path: `${ACTIVATE_EMAIL_PATH}`,
  },
  {
    element: (<Auth />),
    path: FORGOTTEN_PASSWORD_PATH,
  },
  {
    element: (<Payment />),
    path: '/payment',
  },
  {
    element: (<PaymentSuccessful />),
    path: PAYMENT_SUCCESSFUL_PATH,
  },
  {
    element: (<TermsOfUse />),
    path: '/terms-of-use',
  },
  {
    element: (<TermsOfServiceApp />),
    path: '/terms-of-service-app',
  },
  {
    element: (<PersonalData />),
    path: '/privacy-policy',
  },
  {
    element: (<LegalNotice />),
    path: '/legal-notice',
  },
  {
    element: (<Me />),
    path: '/me/*',
  },
  {
    element: (<MyTeam />),
    path: '/my-team',
  },
  {
    element: (<ResetPassword />),
    path: '/reset-password/:token',
  },
  {
    element: (<ThreeDSReturn />),
    path: '/3dsreturn',
  },
  {
    element: (<Presentation />),
    path: '/dpc',
  },
  {
    element: (<Training />),
    path: '/dpc/training',
  },
  {
    element: (<Team />),
    path: '/dpc/team',
  },
  {
    element: (<TrainingSpace />),
    path: '/dpc/training-space',
  },
]

const router = createBrowserRouter(
  routes,
)

const Router = () => {
  return (
    <RouterProvider router={ router } />
  )
}

export default Router
