import React from 'react'
import styled from 'styled-components'
import { Icon } from 'components/atoms'
import FooterItem from './FooterItem'
import { colors } from 'theme'

const Footer = () =>
  <Wrapper>
    <nav>
      <Links>
        <FooterItem
          href="/terms-of-use"
          separator={ false }
        >
          CGV / CGU
        </FooterItem>
        <FooterItem
          href="/legal-notice"
        >
          Mentions légales
        </FooterItem>
        <FooterItem href="/privacy-policy">
          Politique de confidentialité
        </FooterItem>
      </Links>
    </nav>
    <SeparatorLine />
    <CopyRights>
      © DÉFIMÉDOC, Tous droits réservés
    </CopyRights>
    <SocialButtons>
      <Icon
        fill={ colors.lightGreen }
        href="https://twitter.com/defimedoc"
        name="twitterSquare"
        rel="noopener noreferrer"
        size="2.5rem"
        target="_blank"
      />
      <Icon
        fill={ colors.lightGreen }
        href="https://www.facebook.com/defimedoc/"
        name="facebookSquare"
        rel="noopener noreferrer"
        size="2.5rem"
        target="_blank"
      />
      <Icon
        fill={ colors.lightGreen }
        href="https://www.instagram.com/defimedoc/"
        name="instagramSquare"
        rel="noopener noreferrer"
        size="2.5rem"
        target="_blank"
      />
      <Icon
        fill={ colors.lightGreen }
        href="https://www.linkedin.com/company/defimedoc/"
        name="linkedinSquare"
        rel="noopener noreferrer"
        size="2.5rem"
        target="_blank"
      />
    </SocialButtons>
  </Wrapper>

const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.3125rem 0;
  color: ${colors.hanBlue};
  user-select: none;
  background-color: ${colors.midnightBlue};
`

const Links = styled.ul`
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
`

const SeparatorLine = styled.div`
  width: 60%;
  margin: 1.4688rem 0;
  border: 0.5px solid ${colors.hanBlue};
`

const CopyRights = styled.small`
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
`

const SocialButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  gap: 30px;
  margin-top: 2.5rem;
`

export default Footer
