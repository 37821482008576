import React from 'react'
import styled from 'styled-components'
import DrawerProvider from 'context/Drawer'
import SlimHeader from 'components/SlimHeader'
import { HeaderNav } from 'components/me'
import { MyTeam as MyTeamComponent } from 'components/my-team'
import ContactFooter from 'components/ContactFooter'
import Footer from 'components/Footer'

const MyTeam = () => {
  return (
    <>
      <Wrapper>
        <DrawerProvider>
          <SlimHeader>
            <HeaderNav />
          </SlimHeader>
          <SubRouteWrapper>
            <MyTeamComponent />
          </SubRouteWrapper>
          <ContactFooter />
          <Footer />
        </DrawerProvider>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const SubRouteWrapper = styled.section`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-self: center;
  width: 60%;
  min-height: 70vh;
  margin-bottom: 5rem;
  overflow: hidden;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0 3px 24px 0 rgb(0 0 0 / 12%);

  @media (width <= 1300px) {
    width: 100%;
  }
`

export default MyTeam
