import React, { useContext, useEffect } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { USER } from 'graphql/queries/user'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import {
  Header,
  AuthHub,
  Footer,
} from 'components/auth'
import Modal from 'components/Modal'
import { ModalContext } from 'context/Modal'
import { MeQuery } from '@/types/graphql'
import { LANDING_PATH, REGISTER_PATH } from 'components/Router'

const Auth = () => {
  const userQuery = useQuery<MeQuery>(USER)
  const navigate = useNavigate()
  const location = useLocation()
  const { invitationToken: token } = useParams()
  const { actions } = useContext(ModalContext)
  const { closeModal, setModal } = actions

  useEffect(() => {
    if (!userQuery.loading && userQuery.data?.me != null && !token) {
      navigate(LANDING_PATH)
    }

    if (userQuery.data?.me != null && location.pathname === `${REGISTER_PATH}/${token}`) {
      setModal({
        title: 'Avertissement !',
        details: `Vous essayez d'inscrire un nouveau compte utilisateur à partir d'une invitation, alors que vous êtes actuellement connecté(e) en tant que ${userQuery.data.me.firstname} ${userQuery.data.me.lastname.toUpperCase()}.
          Merci de vous déconnecter, avant de réutiliser ce lien d'invitation.
        `,
        cancel: {
          text: 'Continuer',
          handleClick: () => {
            closeModal()
            navigate(LANDING_PATH)
          },
        },
      })
    }
  }, [location.pathname, token, userQuery, closeModal, setModal, navigate])

  if (userQuery.loading || (userQuery.data?.me != null && !token)) {
    return null
  }

  return (
    <>
      <Modal />
      <Wrapper>
        <Main>
          <Header />
          <AuthHub />
          <Footer />
        </Main>
        <Placeholder />
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
`

const Main = styled.div`
  display: flex;
  flex: 0.6;
  flex-direction: column;
  padding: 2rem 4rem;

  @media (width <= 700px) {
    padding: 0;
    margin: 2rem auto;
  }
`

const Placeholder = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  background-image: url(${require('assets/images/authhub/background.jpg')});
  background-repeat: no-repeat;

  @media (width <= 700px) {
    display: none;
  }
`

export default Auth
