import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TeamList from './TeamList'
import { colors } from 'theme'

const dateFormat = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
}

const TeamSummary = ({ subscription }) => {
  const startDate = new Date(Date.parse(subscription.startDate))

  return (
    <Wrapper>
      <Title>
        {`${subscription.teamMembers.length} membres inscrits`}
      </Title>

      <SubscriptionSummary>
        <span>Date de début d&apos;abonnement:</span>
        <br />
        <strong>
          {`${startDate.toLocaleDateString('fr-FR', dateFormat)} - ${subscription.plan.title}`}
        </strong>
      </SubscriptionSummary>

      <TeamList members={ subscription.teamMembers } />
      <Legend>
        <span>
          <strong>&#42;Quiz fait </strong>
          = Quiz terminé, validé ou non. Un Quiz est validé pour un score &ge; à 70/100 points.
        </span>
        <span>
          <strong>&#42;&#42;</strong>
          Depuis l&apos;inscription du bénéficiaire
        </span>
      </Legend>
    </Wrapper>
  )
}

TeamSummary.propTypes = {
  subscription: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    plan: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
    teamMembers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem 3rem;

  @media (width <= 1050px) {
    padding: 2rem 1rem;
  }
`

const Title = styled.h3`
  margin: 2rem 0 1rem;
  font-family: 'Brother 1816';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: bold;
  color: ${colors.lightGreen};
`

const SubscriptionSummary = styled.div`
  margin-bottom: 1.09rem;
  font-family: Montserrat;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: ${colors.midnightBlue};

  > strong {
    font-weight: bold;
  }
`

const Legend = styled.aside`
  display: flex;
  flex-direction: column;
  font-family: 'Brother 1816';
  font-size: 0.875rem;
  font-style: normal;
  color: ${colors.blueGray};

  span {
    margin-bottom: 0.75rem;
  }

  strong {
    font-weight: bold;
  }
`

export default TeamSummary
