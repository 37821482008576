import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Button,
  FormInput,
  FormSelect,
} from 'components/atoms'
import { countries as countriesList } from 'constants/countries'
import { colors } from 'theme'
import { useQuery } from '@apollo/client'
import { ALL_JOBS } from 'graphql/queries/jobs'
import { InputType } from 'components/atoms/Input'

const countriesOptions = countriesList.map(country => ({
  label: country,
  value: country,
}))

const ProfileForm = ({
  editionMode,
  errors,
  form,
  onChange,
  onCancel,
  onSubmit,
}) => {
  const {
    lastname,
    firstname,
    username,
    email,
    job,
    address,
    address2,
    city,
    zipCode,
    country,
  } = form

  const allJobs = useQuery(ALL_JOBS)

  const jobsOptions = useMemo(() => {
    return allJobs.data?.allJobs?.map(job => ({ label: job.title, value: job.title })) ?? []
  }, [allJobs.data])

  const selectedJob = useMemo(() => {
    return jobsOptions.find(jobOption => jobOption.value === job)
  }, [job, jobsOptions])

  const selectedCountry = useMemo(() => {
    return countriesOptions.find(countryOption => countryOption.value === country)
  }, [country])

  const handleSubmit = (e) => {
    e.preventDefault()

    onSubmit(e, form)
  }

  return (
    <Form
      noValidate
      onSubmit={ handleSubmit }
    >
      <FormInput
        error={ errors.lastname }
        label="Nom"
        name="lastname"
        type={ InputType.TEXT }
        value={ lastname }
        onChangeText={ onChange }
      />
      <FormInput
        error={ errors.firstname }
        label="Prénom"
        name="firstname"
        type={ InputType.TEXT }
        value={ firstname }
        onChangeText={ onChange }
      />
      <FormInput
        error={ errors.username }
        label="Pseudo"
        name="username"
        type={ InputType.TEXT }
        value={ username }
        onChangeText={ onChange }
      />
      <FormInput
        disabled={ true }
        error={ errors.email }
        label="Email"
        name="email"
        type={ InputType.EMAIL }
        value={ email }
        onChangeText={ onChange }
      />
      <FormInput
        error={ errors.address }
        label="Adresse"
        name="address"
        type={ InputType.TEXT }
        value={ address }
        onChangeText={ onChange }
      />
      <FormInput
        error={ errors.address2 }
        label="Complément d'adresse"
        name="address2"
        type={ InputType.TEXT }
        value={ address2 }
        onChangeText={ onChange }
      />
      <FormInput
        error={ errors.city }
        label="Ville"
        name="city"
        type={ InputType.TEXT }
        value={ city }
        onChangeText={ onChange }
      />
      <Row>
        <InputWrapper>
          <FormInput
            error={ errors.zipCode }
            label="Code postal"
            name="zipCode"
            type={ InputType.TEXT }
            value={ zipCode }
            onChangeText={ onChange }
          />
        </InputWrapper>
        <InputWrapper>
          <FormSelect
            error={ errors.country }
            label="Pays"
            name="country"
            options={ countriesOptions }
            selectedOption={ selectedCountry }
            onChange={ onChange }
          />
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper>
          <FormSelect
            error={ errors.job }
            label="Profession"
            name="job"
            options={ jobsOptions }
            selectedOption={ selectedJob }
            onChange={ onChange }
          />
        </InputWrapper>
      </Row>
      { editionMode
          &&
          <EditionButtons>
            <CancelEdition
              size="small"
              variant="tertiary"
              onClick={ onCancel }
            >
              Annuler
            </CancelEdition>
            <ConfirmEdition
              size="small"
              type="submit"
              variant="primary"
            >
              Valider
            </ConfirmEdition>
          </EditionButtons>
      }
    </Form>
  )
}

ProfileForm.propTypes = {
  editionMode: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  form: PropTypes.shape({
    lastname: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    address2: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    job: PropTypes.string.isRequired,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

const Form = styled.form`
  margin-bottom: 2.4375rem;
`

const Row = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: flex-end;

  @media (width <= 1050px) {
    flex-wrap: wrap;
  }

  & > div:last-child {
    margin-right: 0;
  }
`

const InputWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 1.3125rem;
`

const EditionButtons = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  margin-top: 2rem;
`

const CancelEdition = styled(Button)`
  && {
    margin-right: 1rem;
  }
`

const ConfirmEdition = styled(Button)`
  && {
    padding: 0.83rem 1.5rem;
    background-color: ${colors.lightGreen};
    background-image: none;
    border-radius: 0;
    box-shadow: none;
  }
`

export default ProfileForm
