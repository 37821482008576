import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, FormPasswordInput } from 'components/atoms'
import { colors } from 'theme'

const ResetPassword = ({
  errors,
  form,
  onChange,
  onSubmit,
}) => {
  const {
    password,
    secondPassword,
  } = form

  return (
    <Wrapper>
      <Title>
        Modifier votre mot de passe
      </Title>
      <form
        id="resetPassword"
        noValidate
        onSubmit={ onSubmit }
      >
        <FormPasswordInput
          error={ errors.password }
          label="Nouveau mot de passe"
          name="password"
          value={ password }
          onChangeText={ onChange }
        />
        <FormPasswordInput
          error={ errors.secondPassword }
          label="Confirmer votre nouveau mot de passe"
          name="secondPassword"
          value={ secondPassword }
          onChangeText={ onChange }
        />

        <ResetPasswordButton
          form="resetPassword"
          size="large"
          type="submit"
          variant="primary"
        >
          Valider votre nouveau mot de passe
        </ResetPasswordButton>
      </form>
    </Wrapper>
  )
}

ResetPassword.propTypes = {
  errors: PropTypes.object.isRequired,
  form: PropTypes.shape({
    password: PropTypes.string.isRequired,
    secondPassword: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
`

const Title = styled.h1`
  font-family: 'Brother 1816';
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 900;
  line-height: 2.7rem;
  color: ${colors.midnightBlue};
`

const ResetPasswordButton = styled(Button)`
  margin: 2rem 0 14rem;
`

export default ResetPassword
