import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from 'theme'

const FormElement = ({
  name,
  label,
  labelId,
  children,
  error,
}) =>
  <>
    <Labels>
      <label
        htmlFor={ name }
        id={ labelId }
      >
        {label}
      </label>
      <HelpText
        $isError={ typeof error === 'string' }
        role="alert"
      >
        {error}
      </HelpText>
    </Labels>
    { children }
  </>

FormElement.propTypes = {
  children: PropTypes.node,
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelId: PropTypes.string,
  name: PropTypes.string.isRequired,
}

FormElement.defaultProps = {
  children: null,
  error: null,
  labelId: undefined,
}

const Labels = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
`

const HelpText = styled.span`
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  color: ${colors.pink};
  opacity: ${({ $isError }) => $isError ? 1 : 0};
  transition: opacity 300ms ease-in-out;
`

export default FormElement
