import React, { FC } from 'react'
import { logError } from 'logService'
import { toast } from 'react-toastify'
import { askResetPassword as ASK_RESET_PASSWORD } from 'graphql/mutations/authentication'
import { useMutation } from '@apollo/client'
import styled from 'styled-components'
import { colors } from 'theme'
import { Button, FormInput, Icon } from 'components/atoms'
import zod from 'zod'
import { AskResetPasswordMutation } from '@/types/graphql'
import useForm from 'hooks/useForm'
import { invalidField, mandatoryField } from 'constants/form'
import { isEmail } from 'utils/regex'
import { InputType } from 'components/atoms/Input'

const schema = zod.object({
  email: zod.string()
    .regex(isEmail, invalidField)
    .min(1, mandatoryField),
})

type Props = {
  onBack: () => void;
}

type ForgottenPasswordForm = zod.infer<typeof schema>

const initialState: ForgottenPasswordForm = {
  email: '',
}

const ForgottenPassword : FC<Props> = (props) => {
  const [askResetPassword] = useMutation<AskResetPasswordMutation>(ASK_RESET_PASSWORD)

  const handleResetPassword = async (form : ForgottenPasswordForm) => {
    try {
      await askResetPassword({
        variables: {
          ...form,
        },
      })

      toast.success('Un email vous a été envoyé', {
        position: 'top-center',
      })
    } catch (e) {
      toast.error('Oups ! Un problème est survenu', {
        position: 'top-center',
      })
      logError('GRAPHQL', 'askResetPassword', e)
    }
  }

  const [data, errors, handleChange, handleSubmit] = useForm(initialState, schema, handleResetPassword)

  return (
    <>
      <BackButton onClick={ props.onBack }>
        <Icon
          fill={ colors.midnightBlue }
          name="back"
          size="1.2em"
        />
        <BackText>Retour</BackText>
      </BackButton>
      <Title>
        Réinitialisez votre mot de passe
      </Title>
      <Explanations>
        Si un compte Défimédoc existe avec l&apos;adresse indiquée, vous recevrez un mail dans les prochaines minutes. Cliquez sur le lien contenu dans l&apos;email pour renseigner un nouveau mot de passe.
        Si vous ne recevez pas de mail vérifiez vos spams.
      </Explanations>
      <form
        onSubmit={ handleSubmit }
      >
        <FormInput
          error={ errors.email }
          label="Email"
          name="email"
          type={ InputType.EMAIL }
          value={ data.email }
          onChangeText={ handleChange }
        />
        <ResetPassword
          size="large"
          type="submit"
          variant="primary"
        >
          Réinitialiser
        </ResetPassword>
      </form>
    </>
  )
}

export default ForgottenPassword

const BackButton = styled.button`
  display: flex;
  flex-shrink: 0;
  align-items: center;
`

const BackText = styled.span`
  margin-left: 1rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
`

const Title = styled.h1`
  margin-top: 3.1rem;
  margin-bottom: 1rem;
  font-family: 'Brother 1816';
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 900;
  line-height: 2.6875rem;
  color: ${colors.midnightBlue};
`

const Explanations = styled.span`
  margin-bottom: 3.1875rem;
  font-family: 'Brother 1816';
  font-style: normal;
  font-weight: normal;
  color: ${colors.midnightBlue};
`

const ResetPassword = styled(Button)`
  margin-top: 1.625rem;
`
