import React, { FC, useEffect, useMemo } from 'react'
import { invalidField, mandatoryField } from 'constants/form'
import { DEFAULT_COUNTRY, countries } from 'constants/countries'
import zod from 'zod'
import useForm from 'hooks/useForm'
import styled from 'styled-components'
import { Button, FormInput, FormSelect } from 'components/atoms'
import { colors } from 'theme'
import { useQuery } from '@apollo/client'
import { BILLING_ADDRESS } from 'graphql/queries/user'
import { MyBillingAddressQuery } from 'types/graphql'
import { InputType } from 'components/atoms/Input'

const AddressFormSchema = zod.object({
  address: zod.string().min(1, mandatoryField),
  additionalAddress: zod.string(),
  addressZipCode: zod.string().regex(/^\d{5}$/, invalidField).min(1, mandatoryField),
  city: zod.string().min(1, mandatoryField),
  country: zod.string().min(1, mandatoryField),
})

export type AdressFormType = zod.infer<typeof AddressFormSchema>

type Props = {
  onFormValidated: (form: AdressFormType) => void;
}

const AddressForm: FC<Props> = ({ onFormValidated }) => {
  const countriesOptions = countries.map(country => ({
    label: country,
    value: country,
  }))

  const { data: userData, loading } = useQuery<MyBillingAddressQuery>(BILLING_ADDRESS)

  const initialState = useMemo(() => {
    const billingAddress = userData?.me?.billingAddress

    return {
      address: billingAddress?.address || '',
      additionalAddress: billingAddress?.address2 || '',
      addressZipCode: billingAddress?.zipCode || '',
      city: billingAddress?.city || '',
      country: billingAddress?.country || DEFAULT_COUNTRY,
    }
  }, [userData])

  const [data, errors, handleChange, handleSubmit, setData] = useForm(initialState, AddressFormSchema, onFormValidated)

  const selectedCountryOption = useMemo(
    () => countriesOptions.find(option => option.value === data.country),
    [data.country, countriesOptions],
  )

  useEffect(() => {
    setData(initialState)
  }, [initialState, setData])

  if (loading || !userData?.me) {
    return null
  }

  return (
    <Wrapper>
      <Title>Votre adresse de facturation</Title>
      <form onSubmit={ handleSubmit }>
        <FormInput
          error={ errors.address }
          label="N° et nom de la voie"
          name="address"
          type={ InputType.TEXT }
          value={ data.address }
          onChangeText={ handleChange }
        />
        <FormInput
          error={ errors.additionalAddress }
          label="Complément d’adresse"
          name="additionalAddress"
          type={ InputType.TEXT }
          value={ data.additionalAddress }
          onChangeText={ handleChange }
        />
        <Row className="wrapable">
          <InputWrapper>
            <FormInput
              error={ errors.addressZipCode }
              label="Code postal"
              name="addressZipCode"
              type={ InputType.TEXT }
              value={ data.addressZipCode }
              onChangeText={ handleChange }
            />
          </InputWrapper>
          <InputWrapper>
            <FormInput
              error={ errors.city }
              label="Ville"
              name="city"
              type={ InputType.TEXT }
              value={ data.city }
              onChangeText={ handleChange }
            />
          </InputWrapper>
        </Row>
        <FormSelect
          error={ errors.country }
          label="Pays"
          name="country"
          options={ countriesOptions }
          selectedOption={ selectedCountryOption }
          onChange={ handleChange }
        />
        <ContinueButton
          size="small"
          type="submit"
          variant="primary"
        >
          Continuer
        </ContinueButton>
      </form>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const Title = styled.h4`
  align-self: center;
  font-family: 'Brother 1816';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: bold;
  color: ${colors.midnightBlue};
`

const Row = styled.div`
  display: flex;
  flex-shrink: 0;
  gap: 1rem;
  align-items: flex-end;

  & > div:last-child {
    margin-right: 0;
  }

  @media (width <= 580px) {
    &.wrapable {
      flex-wrap: wrap;

      & > * {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
`

const InputWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 1.3125rem;
`

const ContinueButton = styled(Button)`
  && {
    align-self: center;
    padding: 0.8rem 2.5rem;
    margin: 2rem 0 1.5rem;
    font-size: 1.5rem;
    border-radius: 0;
  }
`

export default AddressForm
