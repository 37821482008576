import React, { useState } from 'react'
import { logError } from 'logService'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import {
  FormPasswordInput,
  Button,
} from 'components/atoms'
import { UPDATE_MY_PASSWORD } from 'graphql/mutations/user'
import { colors } from 'theme'
import { useMutation } from '@apollo/client'

const ChangePassword = () => {
  const [updateMyPassword] = useMutation(UPDATE_MY_PASSWORD)
  const [form, setForm] = useState({
    oldPassword: '',
    newPassword: '',
    newPassword2: '',
  })

  const [errors, setErrors] = useState({})

  const handleChange = (value, name) => {
    let updatedErrors = { ...errors }

    updateFormValue(value, name)

    setErrors({
      ...updatedErrors,
      [name]: null,
    })
  }

  const updateFormValue = (value, name) => {
    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const {
      oldPassword,
      newPassword,
      newPassword2,
    } = form
    const errors = {}

    if (oldPassword.length === 0) {
      errors.oldPassword = 'Champ obligatoire'
    }
    if (newPassword.length === 0) {
      errors.newPassword = 'Champ obligatoire'
    }
    if (newPassword2.length === 0) {
      errors.newPassword2 = 'Champ obligatoire'
    }
    if (newPassword !== newPassword2) {
      errors.newPassword2 = 'Le nouveau mot de passe ne correspond pas'
    }

    const hasError = Object.values(errors).some(error => typeof error === 'string')

    if (!hasError) {
      try {
        const { data } = await updateMyPassword({
          variables: {
            oldPassword,
            newPassword,
          },
        })
        if (!data.updateMyPassword) {
          errors.oldPassword = 'Mauvais mot de passe'
          setErrors(errors)

          return
        }

        toast.success('Votre mot de passe a été changé', {
          position: 'top-center',
        })
      } catch (e) {
        toast.error('Oups! une erreur est survenue', {
          position: 'top-center',
        })
        logError('GRAPHQL', 'register', e)
      }
    }
    setErrors(errors)
  }

  const {
    oldPassword,
    newPassword,
    newPassword2,
  } = form

  return (
    <form onSubmit={ handleSubmit }>
      <FormPasswordInput
        error={ errors.oldPassword }
        label="Mot de passe actuel"
        name="oldPassword"
        value={ oldPassword }
        onChangeText={ handleChange }
      />
      <FormPasswordInput
        error={ errors.newPassword }
        label="Nouveau mot de passe"
        name="newPassword"
        value={ newPassword }
        onChangeText={ handleChange }
      />
      <FormPasswordInput
        error={ errors.newPassword2 }
        label="Confirmer le nouveau mot de passe"
        name="newPassword2"
        value={ newPassword2 }
        onChangeText={ handleChange }
      />
      <Edit
        type="submit"
        variant="secondary"
      >
        Modifier
      </Edit>
    </form>
  )
}

const Edit = styled(Button)`
  && {
    align-self: flex-end;
    margin-top: 1.3rem;
    font-family: Montserrat;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: bold;
    color: ${colors.lightGreen};
  }
`

export default ChangePassword
